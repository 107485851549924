import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import cristian from "../assets/img/cristiand.png";
import ronaldlaprea from "../assets/img/RonaldLaprea.png";
import mariapaulazarco from "../assets/img/mariapaulazarco.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ReactPlayer from 'react-player';
import lapreast from "../assets/img/lapreast.mp4";
import { useTranslation } from "react-i18next";

export const Projects = () => {
  const [t, i18n] = useTranslation("global");
  const projects = [
    {
      title: "Cristian Camilo Díaz Alvarez",
      description: "Programmer",
      imgUrl: cristian,
    },
    {
      title: "María Paula Zarco Nieto",
      description: "Graphic and UX designer",
      imgUrl: mariapaulazarco,
    },
    
    {
      title: "Ronald Eduardo Laprea Alcala",
      description: "Support: computers, networks, CCTV, printers, servers",
      imgUrl: ronaldlaprea,
    },
    
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>{t("proyectos.titulos")}</h2>
                <p>
                {t("proyectos.descripcion")}
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t("proyectos.pestaña1")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">{t("proyectos.pestaña2")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">{t("proyectos.pestaña3")}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>{t("proyectos.descripcionpestaña2")}</p>
                        <ReactPlayer
                          className='react-player'
                          url={lapreast}
                          controls
                          muted
                          width='500px'
                          height='500px'
                        />      
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <h2>{t("proyectos.pestaña3")}</h2>
                      <h3>{t("proyectos.descripcion1pestaña3")}</h3>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.214870806279!2d-74.0272044!3d4.732700999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f8f89aa3a4455%3A0x134c41c8f1cb5003!2sCl.%20156%20%237f%2050%2C%20Usaqu%C3%A9n%2C%20Bogot%C3%A1%2C%20Cundinamarca!5e0!3m2!1ses!2sco!4v1688786205331!5m2!1ses!2sco" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      <p>{t("proyectos.descripcion3pestaña3")}</p>
                      <p>Calle 156 # 7F - 50 INT 27 Bogotá - Colombia.</p>
                      <p>{t("proyectos.descripcion4pestaña3")}</p>
                      <p>+57 314-358-1851 - +57 319-757-2620</p>
                      <h3>{t("proyectos.descripcion2pestaña3")}</h3>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3026.913190751468!2d-73.59114302543689!3d40.6538439411761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c27b8c074b4d95%3A0xc35266aab6df8604!2s88%20S%20Bergen%20Pl%2C%20Freeport%2C%20NY%2011520%2C%20EE.%20UU.!5e0!3m2!1ses!2sco!4v1688788708726!5m2!1ses!2sco" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      <p>{t("proyectos.descripcion3pestaña3")}</p>
                      <p>88 south Bergen Pl Freeport New York 11756</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
