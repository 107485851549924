import logo from './logo.svg';
import './App.css';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import ReactWhatsapp from "react-whatsapp";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <ReactWhatsapp number="+57 314 3581851" className="" message="we are Laprea´s Technologies, how can we help you?">
      <div id="burbuja" class="burbuja">
            <svg class="burbuja-icono" width="36" height="36" viewBox="0 0 36 36" fill="currentColor">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.7549 5.23159C27.3708 1.85976 22.8701 0.00189519 18.0752 0C8.19527 0 0.154249 8.00196 0.150295 17.8377C0.148977 20.9817 0.974406 24.0507 2.54291 26.7559L0 36L9.50224 33.5194C12.1203 34.9405 15.068 35.6894 18.068 35.6907H18.0754C27.9542 35.6907 35.996 27.6877 36 17.8519C36.0019 13.0854 34.1392 8.60342 30.7549 5.23159ZM18.0754 32.6778H18.0693C15.396 32.6768 12.7739 31.962 10.4865 30.6111L9.94243 30.2898L4.30375 31.7618L5.80885 26.2905L5.45456 25.7295C3.96323 23.3688 3.17553 20.6403 3.1767 17.8388C3.17997 9.66375 9.86341 3.01286 18.0813 3.01286C22.0606 3.01437 25.8013 4.55865 28.6141 7.3612C31.427 10.1637 32.9752 13.889 32.9736 17.8508C32.9702 26.0264 26.2869 32.6778 18.0754 32.6778ZM26.2474 21.5733C25.7995 21.3502 23.5975 20.2718 23.187 20.123C22.7765 19.9743 22.4779 19.8999 22.1793 20.3462C21.8807 20.7924 21.0224 21.7964 20.7611 22.0938C20.4999 22.3913 20.2386 22.4286 19.7907 22.2054C19.3428 21.9823 17.8997 21.5117 16.189 19.9931C14.8576 18.8113 13.9587 17.3515 13.6974 16.9053C13.4362 16.4591 13.6697 16.2178 13.8938 15.9956C14.0953 15.7959 14.3417 15.475 14.5657 15.2147C14.7896 14.9544 14.8642 14.7685 15.0135 14.4711C15.1628 14.1736 15.0882 13.9132 14.9762 13.6901C14.8642 13.4671 13.9685 11.2731 13.5953 10.3807C13.2317 9.51151 12.8625 9.6291 12.5876 9.6155C12.3266 9.60257 12.0277 9.5998 11.7292 9.5998C11.4306 9.5998 10.9454 9.71138 10.5348 10.1576C10.1243 10.6038 8.96734 11.6822 8.96734 13.8761C8.96734 16.07 10.5722 18.1895 10.7961 18.487C11.02 18.7845 13.9543 23.2867 18.4471 25.2174C19.5157 25.6766 20.35 25.9508 21.0004 26.1564C22.0734 26.4955 23.0497 26.4477 23.8214 26.3329C24.6819 26.205 26.4713 25.2546 26.8445 24.2134C27.2178 23.1723 27.2178 22.2798 27.1058 22.0939C26.9938 21.908 26.6953 21.7964 26.2474 21.5733Z"/>
            </svg>
        </div>
        <script src="js/main.js"></script>
      </ReactWhatsapp>
      <Footer />
    </div>
  );
}

export default App;
