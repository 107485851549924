import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import Logo from '../assets/img/Logo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import es from '../assets/img/es.svg';
import gb from '../assets/img/gb.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
import {useTranslation} from 'react-i18next'


export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} alt="Logo" />
            <p className="nomcom">Laprea's Technologies</p>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
              <div id="flags" className="flags">
                <div className="flags_item" data-language="es">
                  <button onClick={()=>i18n.changeLanguage("es")}><img className="flags_img" src={es} alt="spanish" /></button>
                </div>
                <div className="flags_item" data-language="en">
                <button onClick={()=>i18n.changeLanguage("en")}><img className="flags_img" src={gb} alt="english" /></button>
                </div>
              </div>
            </Nav>
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>{t("Navbar.link1")}</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>{t("Navbar.link2")}</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>{t("Navbar.link3")}</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/company/laprea-s-technologies"><img src={navIcon1} alt="" /></a>
                <a href="https://www.facebook.com/profile.php?id=100075765771526"><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/lapreastechnologies/"><img src={navIcon3} alt="" /></a>
              </div>
              <HashLink to='#connect'>
                <button className="vvd"><span>{t("Navbar.conectate")}</span></button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
    </Router>
    
  )
}
