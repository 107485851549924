import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import { useTranslation } from "react-i18next";

export const Skills = () => {
  const [t, i18n] = useTranslation("global");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>{t("Habilidades.titulo1")}</h2>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <h5>{t("Habilidades.subtitulo1")}</h5>
                                <p className="lista">
                                  <ul>
                                    <li>{t("Habilidades.descripcion1subtitulo1")}</li><br/>
                                    <li>{t("Habilidades.descripcion2subtitulo1")}</li><br/>
                                    <li>{t("Habilidades.descripcion3subtitulo1")}</li><br/>
                                    <li>{t("Habilidades.descripcion4subtitulo1")}</li><br/>
                                  </ul>
                                </p>
                            </div>
                            <div className="item">
                                <h5>{t("Habilidades.subtitulo2")}</h5>
                                <p className="lista">
                                <ul>
                                <li>{t("Habilidades.descripcion1subtitulo2")}</li><br/>
                                <li>{t("Habilidades.descripcion2subtitulo2")}</li><br/>
                                <li>{t("Habilidades.descripcion3subtitulo2")}</li><br/>
                                <li>{t("Habilidades.descripcion4subtitulo2")}</li><br/>
                                </ul>
                                </p>
                            </div>
                            <div className="item">
                                <h5>{t("Habilidades.subtitulo3")}</h5>
                                <p className="lista">
                                <ul>
                                <li>{t("Habilidades.descripcion1subtitulo3")}</li><br/>
                                <li>{t("Habilidades.descripcion2subtitulo3")}</li><br/>
                                <li>{t("Habilidades.descripcion3subtitulo3")}</li><br/>
                                <li>{t("Habilidades.descripcion4subtitulo3")}</li><br/>
                                </ul>
                                </p>
                            </div>
                            <div className="item">
                                <h5>{t("Habilidades.subtitulo4")}</h5>
                                <p className="lista">
                                <ul>
                                <li>{t("Habilidades.descripcion1subtitulo4")}</li><br/>
                                <li>{t("Habilidades.descripcion2subtitulo4")}</li><br/>
                                <li>{t("Habilidades.descripcion3subtitulo4")}</li><br/>
                                <li>{t("Habilidades.descripcion4subtitulo4")}</li><br/>
                                  </ul>
                                </p>
                            </div>
                            
                            <div className="item">
                                <h5>{t("Habilidades.subtitulo5")}</h5>
                                <p className="lista">
                                <ul>
                                <li>{t("Habilidades.descripcion1subtitulo5")}</li><br/>
                                <li>{t("Habilidades.descripcion2subtitulo5")}</li><br/>
                                <li>{t("Habilidades.descripcion3subtitulo5")}</li><br/>
                                <li>{t("Habilidades.descripcion4subtitulo5")}</li><br/>
                                </ul>
                                </p>
                            </div>
                            <div className="item">
                                <h5>{t("Habilidades.subtitulo6")}</h5>
                                <p className="lista">
                                <ul>
                                <li>{t("Habilidades.descripcion1subtitulo6")}</li><br/>
                                <li>{t("Habilidades.descripcion2subtitulo6")}</li><br/>
                                <li>{t("Habilidades.descripcion3subtitulo6")}</li><br/>
                                <li>{t("Habilidades.descripcion4subtitulo6")}</li><br/>
                                </ul>
                                </p>
                            </div>
                            <div className="item">
                                <h5>{t("Habilidades.subtitulo7")}</h5>
                                <p className="lista">
                                <ul>
                                  <li>{t("Habilidades.descripcion1subtitulo7")}</li><br/>
                                  <li>{t("Habilidades.descripcion2subtitulo7")}</li><br/>
                                  <li>{t("Habilidades.descripcion3subtitulo7")}</li><br/>
                                  <li>{t("Habilidades.descripcion4subtitulo7")}</li><br/>
                                  </ul>
                                </p>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
